import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { Button, Card, Select, Form, FormProps, Table } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { useQueryClient } from '@tanstack/react-query';

import ExportNationalityStatistic from 'components/common/export-nationality-statistic';
import { useGetAllNationality } from 'hooks/useGetAllNationality';
import { getQuarterDates, toUtcTime } from 'utils';
import QUERY_KEYS from 'services/api/queryKeys';
import { STATUS_NATIONALITY_STATISTIC, TYPE_OF_QUARTERS } from 'constants/form';
import { columnNationalityStatistic } from 'constants/table';
import 'styles/nationality-statistic.scss';
import { NationalityStatisticType } from 'services/api/type/report.type';

function NationalityStatistic() {
  const [form] = Form.useForm();

  const queryClient = useQueryClient();
  const isIpadMobile = useMediaQuery({
    query: '(max-width: 991px)'
  });

  const defaultType = '1';
  const [filterOptions, setFilterOptions] = useState<any>({
    start_date: toUtcTime(getQuarterDates(defaultType).startDate, 'YYYY-MM-DD HH:mm:ss'),
    end_date: toUtcTime(getQuarterDates(defaultType).endDate, 'YYYY-MM-DD HH:mm:ss'),
    status: STATUS_NATIONALITY_STATISTIC.ALLOT_CHECKOUT,
    country: ''
  });
  const [typeOption, setTypeOptions] = useState();

  const { data, isFetching } = useGetAllNationality(filterOptions);

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.GET_ALL_NATIONALITY]
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOptions]);

  const onFinish: FormProps['onFinish'] = async (objValue: any) => {
    const truthyValues: any = Object.keys(objValue)
      .filter(key => Boolean(objValue[key]))
      .reduce((cur, next) => {
        return {
          ...cur,
          [next]: objValue[next]
        };
      }, {});

    const { type, ...restFilterOptions } = truthyValues;
    if (type) {
      setTypeOptions(type);
      const { startDate, endDate } = getQuarterDates(type);
      _.set(restFilterOptions, 'start_date', toUtcTime(startDate, 'YYYY-MM-DD HH:mm:ss'));
      _.set(restFilterOptions, 'end_date', toUtcTime(endDate, 'YYYY-MM-DD HH:mm:ss'));
      _.set(restFilterOptions, 'status', STATUS_NATIONALITY_STATISTIC.ALLOT_CHECKOUT);
    }
    setFilterOptions(restFilterOptions);
  };

  const transformData = (
    data: any[]
  ): {
    statistics: NationalityStatisticType[];
    totalCustomers: number;
    totalMales: number;
    totalFemales: number;
  } => {
    const aggregatedData: { [key: number]: NationalityStatisticType } = {};
    let totalCustomers = 0;
    let totalMales = 0;
    let totalFemales = 0;

    data.forEach(item => {
      const { country_id, country_name, gender, count } = item;
      if (!aggregatedData[country_id]) {
        aggregatedData[country_id] = {
          country_id,
          country_name,
          count: 0,
          male: 0,
          female: 0
        };
      }
      aggregatedData[country_id].count += count;
      if (gender === 'male') {
        aggregatedData[country_id].male += count;
        totalMales += count;
      } else if (gender === 'female') {
        aggregatedData[country_id].female += count;
        totalFemales += count;
      }
      totalCustomers += count;
    });

    return {
      statistics: Object.values(aggregatedData),
      totalCustomers,
      totalMales,
      totalFemales
    };
  };

  const { statistics, totalCustomers, totalMales, totalFemales } = transformData(data || []);
  const dataToExport = statistics.map(item => ({
    country_id: item.country_id,
    country_name: item.country_name,
    male: item.male || 0,
    female: item.female || 0,
    count: item.count || 0
  }));
  return (
    <div className="pms-nationality-statistic">
      <Card title="Danh sách quốc tịch khách tạm trú" style={{ width: '100%' }}>
        <Form
          form={form}
          name="nationality-statistic-form"
          layout={isIpadMobile ? 'vertical' : 'inline'}
          initialValues={{
            type: defaultType,
            country: '',
            status: STATUS_NATIONALITY_STATISTIC.ALLOT_CHECKOUT
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <div className="pms-nationality-statistic__filter-wrapper">
            <div className="pms-nationality-statistic__filter">
              <Form.Item name="type">
                <Select className="w-full" placeholder="Chọn quý">
                  {TYPE_OF_QUARTERS.map(quarter => (
                    <Select.Option key={quarter} value={quarter}>
                      Quý {quarter}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Button htmlType="submit" className="ant-btn-secondary btn-submit">
                Tìm kiếm
              </Button>
            </div>

            <ExportNationalityStatistic
              filterOptions={{ filterOptions }}
              fileName={`nationality-statistic-${filterOptions.start_date}-${filterOptions.end_date}-${nanoid()}`}
              quarterType={typeOption}
              dataToExport={dataToExport}
              totalCustomers={totalCustomers}
              totalMales={totalMales}
              totalFemales={totalFemales}
            />
          </div>
        </Form>

        <div className="pms-nationality-statistic__table">
          <Table
            loading={isFetching}
            rowKey="country_id"
            columns={columnNationalityStatistic}
            dataSource={statistics}
            locale={{
              emptyText: <span className="empty-data">Không có dữ liệu</span>
            }}
            scroll={{ x: 600, y: 'calc(100vh - 400px)' }}
          />
        </div>
      </Card>
    </div>
  );
}

export default NationalityStatistic;
