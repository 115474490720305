import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import API from 'services/api/index';
import QUERY_KEYS from 'services/api/queryKeys';

export const useGetReservationForecast = (start_date: string, period: number) => {
  const { data, status, isLoading, isFetching } = useQuery({
    queryKey: [QUERY_KEYS.GET_RESERVATION_FORECAST, start_date, period],
    queryFn: () => API.report.getReservationForecast(start_date, period),
    enabled: !!start_date && !!period
  });

  const convertedData = useMemo(() => {
    return Object.keys(data || {}).map(key => ({
      ...data[key],
      date: key
    }));
  }, [data]);

  return {
    data: convertedData || [],
    status,
    isLoading,
    isFetching
  };
};
