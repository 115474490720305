import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { Button, Card, Input, Form, FormProps, Table, notification } from 'antd';
import { useMutation } from '@tanstack/react-query';

import { useGetRoomLock } from 'hooks/useGetRoomLock';
import useBookingStore from 'stores/useBooking';
import useModal from 'stores/useModal';

import { getColumnRoomLock } from 'constants/table';
import { unlockRoom } from 'services/api/module/room.api';
import { queryClient } from 'index';
import QUERY_KEYS from 'services/api/queryKeys';
import 'styles/room-lock.scss';

function RoomLock() {
  const [form] = Form.useForm();
  const { setRoom } = useBookingStore();
  const { setIsOpenFixRoom, setInfoConfirmModal, setConfirmLoading } = useModal();

  const [roomId, setRoomId] = useState<string | number>('');
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const { data, isFetching } = useGetRoomLock(roomId);

  const { mutateAsync: mutateUnlock } = useMutation({
    mutationFn: (lock_ids: React.Key[]) => unlockRoom({ lock_ids })
  });

  const onFinish: FormProps['onFinish'] = async (objValue: any) => {
    const truthyValues: any = Object.keys(objValue)
      .filter(key => Boolean(objValue[key]))
      .reduce((cur, next) => {
        return {
          ...cur,
          [next]: objValue[next]
        };
      }, {});
    setRoomId(truthyValues.room_id);
  };

  const onFinishFailed: FormProps['onFinishFailed'] = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleUnlockSelected = () => {
    setInfoConfirmModal(true, {
      title: 'Xác nhận mở khóa phòng',
      okText: 'Xác nhận',
      onOk: async () => {
        if (!_.isEmpty(selectedRowKeys)) {
          try {
            setConfirmLoading(true);
            await mutateUnlock(selectedRowKeys);

            queryClient.invalidateQueries({
              queryKey: [QUERY_KEYS.GET_ROOM_LOCK]
            });
            setInfoConfirmModal(false);
            setSelectedRowKeys([]);
            notification.success({
              message: 'Mở khóa phòng thành công'
            });
          } catch (err: any) {
            notification.error({
              message: 'Mở khóa phòng thất bại'
            });
          } finally {
            setConfirmLoading(false);
          }
        }
      }
    });
  };

  const handleOpenFixRoomModal = () => {
    setIsOpenFixRoom(true, /* isCreateFixRoom = */ true);
  };

  const columnRoomLock = useMemo(() => {
    const handleEdit = (roomId: number) => {
      const roomInfo = data?.find(room => room.room_id === roomId);
      if (roomInfo) {
        setRoom(roomId, roomInfo.room_name, {
          start_date: roomInfo.start_date,
          end_date: roomInfo.end_date,
          reason: roomInfo.reason,
          lock_id: roomInfo.id
        });
        setIsOpenFixRoom(true);
      }
    };

    return getColumnRoomLock(handleEdit);
  }, [data, setIsOpenFixRoom, setRoom]);

  return (
    <div className="pms-room-lock">
      <Card title="Tất cả" style={{ width: '100%' }}>
        <Form
          form={form}
          name="room-lock-filter-form"
          layout="inline"
          initialValues={{
            booking_id: '',
            rangeDate: null,
            status: ''
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <div className="pms-room-lock__filter">
            <Form.Item name="room_id" style={{ marginRight: 0 }}>
              <Input placeholder="Mã phòng" />
            </Form.Item>

            <Button htmlType="submit" className="ant-btn-secondary btn-submit">
              Tìm kiếm
            </Button>

            <Button
              type="primary"
              onClick={handleUnlockSelected}
              disabled={selectedRowKeys.length === 0}
            >
              Mở khóa phòng
            </Button>

            <Button type="primary" onClick={handleOpenFixRoomModal}>
              Tạo khóa phòng
            </Button>
          </div>
        </Form>

        <div className="pms-room-lock__table">
          <Table
            loading={isFetching}
            rowKey="id"
            columns={columnRoomLock}
            dataSource={data}
            locale={{
              emptyText: <span className="empty-data">Không có dữ liệu</span>
            }}
            scroll={{ x: 500, y: 'calc(100vh - 320px)' }}
            rowSelection={{
              selectedRowKeys,
              onChange: onSelectChange
            }}
          />
        </div>
      </Card>
    </div>
  );
}

export default RoomLock;
