// import _ from "lodash";
import React, { useMemo, useState } from 'react';
import dayjs from 'dayjs';
// import classNames from "classnames";
import { DatePicker, Select, Table, TableProps } from 'antd';

import { RANGE_DATE_CLEANING_DETAIL_OPTIONS } from 'constants/common';

import { useGetRoomAvailability } from 'hooks/useGetRoomAvailability';
import { toUtcTime } from 'utils';

import 'styles/room-availability.scss';

const INITIAL_RANGE_DATE = 14;
const today = dayjs().startOf('day');

function RoomAvailability() {
  const [date, setDate] = useState(today);
  const [rangeDate, setRangeDate] = useState(INITIAL_RANGE_DATE);
  const { data: roomAvailability, isFetching } = useGetRoomAvailability(
    String(toUtcTime(date, 'YYYY-MM-DD')),
    rangeDate
  );

  // const onSearch: FormProps["onFinish"] = async (values: any) => {};

  const handleChangeDate = (value: any) => {
    setDate(value);
  };

  const handleChangeRangeDate = (value: number) => {
    setRangeDate(value);
  };

  const columnCleaningRoom: TableProps<any>['columns'] = useMemo(() => {
    let startDate = dayjs(date);
    const endDate = date.add(rangeDate, 'day');
    const columns: TableProps<any>['columns'] = [
      {
        title: 'Loại phòng',
        dataIndex: 'roomType',
        key: 'roomType',
        fixed: 'left',
        width: 300
      }
    ];

    while (!startDate.isSame(endDate)) {
      const addedColumn = startDate.format('YYYY-MM-DD');
      columns.push({
        align: 'center',
        title: startDate.format('DD/MM'),
        dataIndex: addedColumn,
        key: addedColumn,
        width: 150,
        render: (text: any) => <p className="m-0 text-right">{text}</p>
      });
      startDate = startDate.add(1, 'day');
    }

    return columns;
  }, [date, rangeDate]);

  return (
    <div className="pms-room-availability">
      <div className="pms-room-availability__timetable">
        <div className="pms-room-availability__filter">
          <div className="pms-room-availability__filter__left">
            <DatePicker value={date} onChange={handleChangeDate} placeholder="Chọn ngày" />

            <Select
              placeholder="Chọn bao lâu"
              options={RANGE_DATE_CLEANING_DETAIL_OPTIONS}
              value={rangeDate}
              onChange={handleChangeRangeDate}
            />
          </div>
        </div>

        <div className="pms-room-availability__table">
          <Table
            rowKey="roomType"
            loading={isFetching}
            columns={columnCleaningRoom}
            dataSource={roomAvailability}
            pagination={false}
            scroll={{ x: 1000, y: 'calc(100vh - 260px)' }}
            bordered
            locale={{
              emptyText: <span className="empty-data">Không có dữ liệu</span>
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default RoomAvailability;
