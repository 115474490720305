import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { getAvailRoomType } from 'services/api/module/booking.api';
import QUERY_KEYS from 'services/api/queryKeys';

export const useGetAvailRoomType = (startDate?: string, endDate?: string) => {
  const { data, status, isLoading, isFetching } = useQuery({
    queryKey: [QUERY_KEYS.KEY_ROOM, QUERY_KEYS.GET_AVAIL_ROOM_TYPE, startDate, endDate],
    queryFn: () => {
      if (!startDate || !endDate) {
        return;
      }
      return getAvailRoomType(startDate, endDate);
    },
    enabled: !!startDate && !!endDate
  });

  const cookedData = useMemo(() => {
    return (data || []).map(item => ({
      ...item,
      used_rooms: 0
    }));
  }, [data]);

  return {
    data: cookedData,
    status,
    isLoading,
    isFetching
  };
};
