import React, { useState, useEffect, useMemo } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker, Button, Table, Space } from 'antd';
import { RANGE_DATE_CLEANING_DETAIL_OPTIONS } from 'constants/common';
import { useGetReservationForecast } from 'hooks/useGetReservationForecast';
import { toUtcTime } from 'utils';
import { columnReservationForecast } from 'constants/table';
import 'styles/reservation-forecast.scss';

const INITIAL_RANGE_DATE = 14;
const today = dayjs().startOf('day');

type DateRange = [Dayjs | null, Dayjs | null] | null;

function ReservationForecast() {
  const [date, setDate] = useState<[Dayjs, Dayjs]>([today, today.add(INITIAL_RANGE_DATE, 'day')]);
  const [rangeDate, setRangeDate] = useState<number>(INITIAL_RANGE_DATE);
  const [isDatePickerEmpty, setIsDatePickerEmpty] = useState<boolean>(false);

  const { data: reservationForecast, isFetching } = useGetReservationForecast(
    String(toUtcTime(date[0].add(1, 'day'), 'YYYY-MM-DD')),
    rangeDate || INITIAL_RANGE_DATE
  );

  const dataSource = useMemo(() => {
    if (reservationForecast) {
      const dataArray = Object.entries(reservationForecast).map(([date, data]) => ({
        date,
        ...data
      }));

      const totalOccupiedPercent = dataArray.reduce(
        (sum, item) => sum + (item.occupied?.occupied_percent || 0),
        0
      );
      const totalRevenue = dataArray.reduce(
        (sum, item) => sum + (item.financials?.revenue || 0),
        0
      );
      const totalGrossProfit = dataArray.reduce(
        (sum, item) => sum + (item.financials?.gross_profit || 0),
        0
      );
      const totalNetProfit = dataArray.reduce(
        (sum, item) => sum + (item.financials?.net_profit || 0),
        0
      );
      const averageOccupiedPercent =
        dataArray.length > 0 ? totalOccupiedPercent / dataArray.length : 0;

      const averageRow = {
        date: (
          <span
            style={{
              fontWeight: 'bold',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%'
            }}
          >
            TOTAL
          </span>
        ),
        check_in: { room: '', pax: '' },
        check_out: { room: '', pax: '' },
        occupied: {
          room: '',
          occupied_percent: averageOccupiedPercent.toFixed(2),
          pax: ''
        },
        financials: {
          revenue: totalRevenue,
          gross_profit: totalGrossProfit,
          net_profit: totalNetProfit
        }
      };

      return [...dataArray, averageRow];
    }
    return [];
  }, [reservationForecast]);

  const resetDate = () => {
    setIsDatePickerEmpty(true);
    setRangeDate(INITIAL_RANGE_DATE);
  };

  const handleChangeDate = (dates: DateRange, dateStrings: [string, string]) => {
    if (dates && dates[0] && dates[1]) {
      // Convert the selected dates to the local timezone
      const startDate = dayjs(dateStrings[0]);
      const endDate = dayjs(dateStrings[1]);
      setDate([startDate, endDate]);
      const diffInDays = endDate.diff(startDate, 'day');
      setRangeDate(diffInDays);
      setIsDatePickerEmpty(false);
    } else {
      resetDate();
    }
  };

  const handleChangeRangeDate = (value: number) => {
    setRangeDate(value);
    const newEndDate = date[0].add(value, 'day');
    setDate([date[0], newEndDate]);
  };

  useEffect(() => {
    const startDate = date[0];
    const endDate = startDate.add(rangeDate, 'day');
    setDate([startDate, endDate]);
    // eslint-disable-next-line
  }, [rangeDate]);

  return (
    <div className="pms-reservation-forecast">
      <div className="pms-reservation-forecast__timetable">
        <div className="pms-reservation-forecast__filter">
          <div className="pms-reservation-forecast__filter__left">
            <DatePicker.RangePicker
              className="w-full"
              placeholder={['Start Date', 'End Date']}
              format="YYYY-MM-DD HH:mm"
              value={isDatePickerEmpty ? null : date}
              onChange={handleChangeDate}
              renderExtraFooter={() => (
                <Space
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%'
                  }}
                >
                  {RANGE_DATE_CLEANING_DETAIL_OPTIONS.map(option => (
                    <Button
                      key={option.value}
                      type={rangeDate === option.value ? 'primary' : 'default'}
                      onClick={() => handleChangeRangeDate(option.value)}
                    >
                      {option.label}
                    </Button>
                  ))}
                </Space>
              )}
            />
          </div>
        </div>
        <div className="pms-reservation-forecast__table">
          <Table
            rowKey="date"
            loading={isFetching}
            columns={columnReservationForecast}
            dataSource={dataSource}
            pagination={false}
            scroll={{ x: 1000, y: 'calc(100vh - 320px)' }}
            bordered
            locale={{
              emptyText: <span className="empty-data">Không có dữ liệu</span>
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default ReservationForecast;
