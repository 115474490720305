import { useQuery } from '@tanstack/react-query';
import API from 'services/api/index';
import QUERY_KEYS from 'services/api/queryKeys';

export const useGetRoomLock = (roomId?: number | string) => {
  const { data, isFetching } = useQuery({
    queryKey: [QUERY_KEYS.GET_ROOM_LOCK, roomId],
    queryFn: () => API.room.getRoomLock(roomId)
  });

  return {
    data,
    isFetching
  };
};
