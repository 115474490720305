import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { matchPath } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Modal, Form, FormProps, Row, Col, Select, Checkbox, Spin, notification } from 'antd';
import { IdcardOutlined } from '@ant-design/icons';

import { useGetAvailRoomType } from 'hooks/useGetAvailRoomType';
import useModalStore from 'stores/useModal';
import useBookingStore from 'stores/useBooking';

import { ReactComponent as IconClose } from 'assets/images/close.svg';
import { ReactComponent as IconCancelCalendar } from 'assets/images/cancel-calendar.svg';

import { RoomType } from 'services/api/type/room.type';
import { formatCurrency, toUtcTime } from 'utils';
import { assignRoom } from 'services/api/module/room.api';
import { FILTER_STATUS_ROOM_MAP_VALUE } from 'constants/common';
import QUERY_KEYS from 'services/api/queryKeys';
import PAGES from 'routes/constants';

function AssignRoomModal() {
  const [formAssign] = Form.useForm();
  const queryClient = useQueryClient();

  const { isOpenAssignRoom, setIsOpenAssignRoom } = useModalStore();
  const { bookingLineDetail } = useBookingStore();

  const roomTypeId: number = Form.useWatch('room_type_id', formAssign);
  const isChangeRate: boolean = Form.useWatch('is_change_rate', formAssign);
  const priceListId: number = Form.useWatch('pricelist_id', formAssign);

  const [availableRooms, setAvailableRooms] = useState<any[]>([]);
  const [pricingList, setPricingList] = useState<any[]>([]);

  const [rangeDate, setRangeDate] = useState(['', '']);
  const { data: availRoomTypes, isFetching } = useGetAvailRoomType(
    toUtcTime(rangeDate[0], 'YYYY-MM-DD HH:mm:ss'),
    toUtcTime(rangeDate[1], 'YYYY-MM-DD HH:mm:ss')
  );

  const { mutateAsync: mutateAssign, isPending: isPendingAssign } = useMutation({
    mutationFn: (params: any = {}) => assignRoom(params)
  });

  useEffect(() => {
    if (!_.isEmpty(bookingLineDetail)) {
      setRangeDate([bookingLineDetail?.check_in || '', bookingLineDetail?.check_out || '']);
    }
  }, [bookingLineDetail]);

  useEffect(() => {
    if (roomTypeId) {
      const roomType: RoomType | undefined = _.find(availRoomTypes, {
        room_type_id: roomTypeId
      });
      if (roomType) {
        setAvailableRooms(
          roomType.rooms
            .filter(item => item.room_status !== FILTER_STATUS_ROOM_MAP_VALUE.BOOKED)
            .map(item => ({
              label: item.room_name,
              value: item.room_id,
              disabled: item.room_status !== FILTER_STATUS_ROOM_MAP_VALUE.AVAILABLE
            }))
            .sort((a, b) => (a.disabled === b.disabled ? 0 : a.disabled ? 1 : -1))
        );

        const pricingList = [
          {
            label: `Giá mặc định - ${formatCurrency(roomType.price)}`,
            value: ''
          },
          ...(roomType.pricing || []).map(item => ({
            label: `${item.pricelist_name} - ${formatCurrency(item.price)}`,
            value: item.pricelist_id
          }))
        ];
        setPricingList(pricingList);

        formAssign.setFieldsValue({
          room_id: null,
          pricelist_id: ''
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formAssign, roomTypeId]);

  useEffect(() => {
    if (isChangeRate && !priceListId) {
      formAssign.setFieldValue('pricelist_id', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChangeRate, priceListId]);

  const handleCloseAssignRoom = () => {
    setIsOpenAssignRoom(false);
    formAssign.resetFields();
    formAssign.setFieldValue('room_type_id', bookingLineDetail?.room_type_id);
  };

  const handleAssignRoom = async () => {
    formAssign.submit();
  };

  const onFinishAssign: FormProps['onFinish'] = async (values: any) => {
    const { is_change_rate, pricelist_id, room_id } = values;
    if (bookingLineDetail) {
      try {
        await mutateAssign({
          booking_line_id: bookingLineDetail.booking_line_id,
          room_id,
          is_change_rate,
          pricelist_id: pricelist_id || undefined
        });

        queryClient.invalidateQueries({
          queryKey: [
            QUERY_KEYS.KEY_ROOM,
            QUERY_KEYS.GET_BOOKING_DETAIL,
            bookingLineDetail.booking_line_id
          ]
        });
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.KEY_ROOM, QUERY_KEYS.GET_BOOKING_LIST]
        });

        if (matchPath(PAGES.cleaningCalendar, location.pathname)) {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.GET_CLEANING_CALENDAR]
          });
        }
        setIsOpenAssignRoom(false);
      } catch (err: any) {
        notification.error({
          message: err.error || 'Có lỗi xảy ra'
        });
      }
    }
  };

  const roomTypeOptions = useMemo(() => {
    return (availRoomTypes || []).map(item => ({
      label: item.room_type_name,
      value: item.room_type_id
    }));
  }, [availRoomTypes]);

  return (
    <Modal
      title="Gán phòng"
      centered
      width={410}
      open={isOpenAssignRoom}
      onCancel={handleCloseAssignRoom}
      closeIcon={<IconClose />}
      okText="Lưu"
      cancelText="Đóng"
      onOk={handleAssignRoom}
      okButtonProps={{ className: 'ant-btn-secondary' }}
      className="modal-assign-room"
      confirmLoading={isPendingAssign}
      destroyOnClose
    >
      {isFetching ? (
        <Spin className="flex items-center justify-center" style={{ height: '200px' }} />
      ) : (
        <Form
          form={formAssign}
          name="assign-room-form"
          layout="vertical"
          style={{ width: '100%' }}
          initialValues={{
            room_type_id: bookingLineDetail?.room_type_id,
            room_id: bookingLineDetail?.room_id,
            is_change_rate: false,
            pricelist_id: null
          }}
          onFinish={onFinishAssign}
          autoComplete="off"
        >
          <Row gutter={[8, 8]}>
            <Col span={12}>
              <div className="col-type-room">
                <h4 className="title m-0">
                  <IconCancelCalendar />
                  {bookingLineDetail?.room_name || bookingLineDetail?.room_type_name + ' N/A'}
                </h4>
                <Form.Item label="Loại phòng" name="room_type_id">
                  <Select options={roomTypeOptions} />
                </Form.Item>
              </div>
            </Col>
            <Col span={12}>
              <div className="col-room">
                <div className="title">
                  <IdcardOutlined />
                  <p className="m-0 truncate" title={bookingLineDetail?.partner_name || 'Guest'}>
                    {bookingLineDetail?.partner_name || 'Guest'}
                  </p>
                </div>
                <Form.Item label="Chọn phòng" name="room_id">
                  <Select options={availableRooms} placeholder="Chọn phòng" />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Form.Item name="is_change_rate" valuePropName="checked" style={{ marginBottom: '12px' }}>
            <Checkbox>Đổi loại giá</Checkbox>
          </Form.Item>

          {isChangeRate && (
            <Form.Item name="pricelist_id">
              <Select options={pricingList} />
            </Form.Item>
          )}
        </Form>
      )}
    </Modal>
  );
}

export default AssignRoomModal;
