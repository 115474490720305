import _ from 'lodash';
import { useQuery } from '@tanstack/react-query';
import API from 'services/api/index';
import QUERY_KEYS from 'services/api/queryKeys';
import { PaginationType } from 'services/api/type/booking.type';
import { PAGE_SIZE_CASH_FLOW } from 'services/api/module/report.api';
import { CashFlowType } from 'services/api/type/report.type';

interface FilterType {
  start_date: string;
  end_date: string;
}

export const useGetCashFlow = (
  filterOptions: FilterType,
  page: number,
  pageSize: number = PAGE_SIZE_CASH_FLOW
) => {
  const { data, status, isLoading, isFetching } = useQuery({
    queryKey: [QUERY_KEYS.GET_CASH_FLOW, page],
    queryFn: () =>
      API.report.getCashFlow({
        ...filterOptions,
        page,
        limit: pageSize
      })
  });

  return {
    data: _.get(data, 'data', []) as CashFlowType[],
    pagination: _.get(data, 'pagination', {}) as PaginationType,
    status,
    isLoading,
    isFetching
  };
};

export const useGetCashFlowAll = (filterOptions: FilterType) => {
  const { data, status, isLoading, isFetching } = useQuery({
    queryKey: [QUERY_KEYS.GET_CASH_FLOW_ALL],
    queryFn: () =>
      API.report.getCashFlow({
        ...filterOptions
      })
  });

  return {
    data: _.get(data, 'data', []) as CashFlowType[],
    status,
    isLoading,
    isFetching
  };
};
